document.addEventListener("DOMContentLoaded", function () {
  const call = document.querySelector(".whatsapp-widget__call");

  document
    .querySelector(".whatsapp-widget__close-call")
    .addEventListener("click", function () {
      call.hidden = true;
    });

  setTimeout(function () {
    call.hidden = false;
  }, 10 * 1000);
});
